import styled from 'styled-components';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import colours from 'constants/colours';
import device from 'constants/devices';

export const InputGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputSelect = styled(FormControlLabel)`
  border-radius: 10px;
  border: 2px solid ${colours.neutralCool[200]};
  display: flex;
  gap: 10px;
  padding: 15px 10px;
`;

export const InputTextArea = styled(TextField)`
  fieldset: {
    border-radius: 10px;
    border: 2px solid ${colours.neutralCool[200]};
  }
`;

export const ButtonsColumn = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PromptButton = styled(Button)`
  background-color: ${colours.neutralCool[100]};
  border-radius: 10px;
  color: ${colours.neutralCool[800]};
  justify-content: unset;
  line-height: unset;
  width: 400px;
  padding: 10px;
  text-align: left;
  text-transform: none;
  :hover {
    background-color: ${colours.neutralCool[200]};
  }
  @media (max-width: ${device.tablet}) {
    width: 100%;
  }
`;

export const PromptButtonDark = styled(PromptButton)`
  background-color: ${colours.neutralCool[500]};
  color: ${colours.shade[0]};
  :hover {
    background-color: ${colours.neutralCool[200]};
  }
`;

export const PromptButtonPrimary = styled(PromptButton)`
  background-color: ${colours.primary[500]};
  color: ${colours.shade[0]};
  :hover {
    background-color: ${colours.primary[600]};
  }
`;
