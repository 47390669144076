import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

// constants
import routesConfig from 'constants/routesConfig.json';

// components, styles, assets
import colours from 'constants/colours';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import ConfirmDialog from 'components/_pageLayout/ConfirmDialog';
import * as History from 'components/pageECoach/_styles/History.style';
import * as Text from 'components/_styles/Text.style';
import { General } from 'untitledui-js';
import { Stack } from '@mui/material';

// hooks
import useApi from 'hooks/useApi';
import useUserLanguage from 'hooks/useUserLanguage';

export default function ECoachSessionList({ navigate }) {
  const { data: sessionList, callApi: getSessionList } = useApi();
  const { callApi: updateSession } = useApi();
  const { t } = useTranslation('pageEcoach');
  const lang = useUserLanguage();

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [sessionId, setSessionId] = useState();
  const handleConfirmDialogClose = () => setIsOpenConfirmDialog(false);
  const handleConfirmDialogOpen = (e, session) => {
    e.stopPropagation();
    setSessionId(session);
    setIsOpenConfirmDialog(true);
  };

  useEffect(() => {
    getSessionList(routesConfig.ECOACH.SESSIONS);
  }, [getSessionList]);

  const hideSession = () => {
    updateSession(`${routesConfig.ECOACH.SESSIONS}${sessionId}/hide`, 'put');
    window.location.reload();
  };

  const confirmDialog = (
    <ConfirmDialog
      cancelLabel={t('No')}
      successLabel={t('Yes')}
      successCallback={() => hideSession(sessionId)}
      contentText={t('Are you sure you want to delete this session?')}
      handleClose={handleConfirmDialogClose}
      isOpen={true}
    />
  );

  const sessions = sessionList.status === 'success' ? sessionList.response : [];

  if (sessions?.length === 0) return <></>;
  return (
    <Stack spacing={4}>
      <Text.SubheadingSemibold>
        {t('Previous sessions')}
      </Text.SubheadingSemibold>
      <Cards.MultiCardSection>
        {sessions.map((session) => (
          <History.Card key={session.id} onClick={() => navigate(session.id)}>
            <Stack spacing={1}>
              <Stack justifyContent={'space-between'} direction={'row'}>
                <History.Date>
                  {dayjs().locale(lang).to(dayjs(session.created_at))}
                </History.Date>
                <History.DeleteIcon>
                  <General.Trash01
                    onClick={(e) => handleConfirmDialogOpen(e, session.id)}
                    stroke={colours.neutralCool[600]}
                    size="15"
                    whileHover={colours.primary[500]}
                  />
                </History.DeleteIcon>
              </Stack>
              <Text.P1Semibold>{session.description}</Text.P1Semibold>
            </Stack>
            <History.BottomBlur />
          </History.Card>
        ))}
      </Cards.MultiCardSection>
      {isOpenConfirmDialog && confirmDialog}
    </Stack>
  );
}

ECoachSessionList.propTypes = {
  navigate: PropTypes.func,
};
