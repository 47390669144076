import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useApi from 'hooks/useApi';
import userRoles from 'constants/userRoles.json';
import { usePostHog } from 'posthog-js/react';

// User context storage
const UserContext = createContext({});

// User state retieval function
const useUser = () => useContext(UserContext);

// Function for populating user state
// UserProvider is called in the index.js file, in order for App.js to assess if user is logged in
function UserProvider({ children }) {
  const { data, callApi } = useApi();
  const posthog = usePostHog();
  const [userRole, setUserRole] = useState('');

  // Collect user info from database and set global user state
  useEffect(() => {
    callApi('/users/me/');
  }, [callApi]);

  // Set an initial userRole
  useEffect(() => {
    if (data.response?.owl) setUserRole(userRoles.OWL);
    if (data.response?.organization_admin) setUserRole(userRoles.ADMIN);
    if (data.response?.support_admin) setUserRole(userRoles.SUPPORT_ADMIN);
    if (data.response?.member) setUserRole(userRoles.MEMBER);
  }, [data.response]);

  if (data.response && data.response.email) {
    const user = data.response;
    posthog.alias(user.email);
    posthog.identify(user.email, {
      id: user.id,
      email: user.email,
      categories: user.categories
        .filter((c) => c.category_field !== null)
        .map((c) => `${c.category} - ${c.category_field}`),
      scopes: user.scopes.map((scope) => scope.full_scope),
      company: user.organization.name,
      language: user.language,
    });
    posthog.group('company', user.organization.id, {
      name: user.organization.name,
    });
  }

  return (
    <UserContext.Provider
      value={{
        data: data.response,
        callApi,
        userRole,
        setUserRole,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node,
};

export { UserContext, useUser, UserProvider };
