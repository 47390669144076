import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { usePostHog } from 'posthog-js/react';

// components
import ChatBubble from 'components/pageECoach/cardTemplates/ChatBubble';
import SubmitButton from 'components/pageECoach/cardTemplates/SubmitButton';
import AboutCard from 'components/pageECoach/conversationPlan/AboutCard';

// style
import * as PromptHelperStyle from 'components/pageECoach/_styles/PromptHelper.style';
import * as Page from 'components/pageECoach/_styles/ChatArea.style';
import { SessionProgressChoices } from '../utils';
import { useSessionContext } from '../../../contexts/ECoachSessionContext';

function FormTextField({ id, label, placeholder, value, onChange, required }) {
  return (
    <TextField
      id={id}
      label={label}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      variant="standard"
      required={required}
      multiline
      value={value}
      onChange={onChange}
    />
  );
}

export default function ConversationPlanForm({ handleSubmit }) {
  const { t } = useTranslation('pageEcoach');
  const posthog = usePostHog();

  const { session, updateSession } = useSessionContext();

  const [formData, setFormData] = useState(session?.context?.convo_prep || {});

  const updateFormData = useCallback((key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  }, []);

  const onSubmit = useCallback(() => {
    updateSession(session.id, {
      context: { convo_prep: formData },
      progress: SessionProgressChoices.CONVERSATION_PLAN,
    });
    posthog?.capture('user_submits_convo_prep_form');
    handleSubmit();
  }, [formData, updateSession, handleSubmit, posthog, session.id]);

  const handleChange = (key) => (event) => {
    updateFormData(key, event.target.value);
  };

  const header = t(
    'convoPrep.Customize and fill in the blanks for the best outcome.'
  );

  return (
    <Page.ECoachSection>
      <AboutCard />
      <ChatBubble divId="convoPrepForm" header={header} pointer={true}>
        <PromptHelperStyle.FillInTheBlanksSection id="fill-in-blanks">
          <FormTextField
            id="goal"
            label={`${t(
              'convoPrep.My main goal going into this meeting is'
            )}...`}
            placeholder={t(
              'convoPrep.e.g., to get a member of my team to take more initiative'
            )}
            value={formData.goal || ''}
            onChange={handleChange('goal')}
            required={false}
          />
          <FormTextField
            id="worried-by"
            label={`${t("convoPrep.I'm worried that")}...`}
            placeholder={t(
              'convoPrep.e.g., they are not going to be open to feedback.'
            )}
            value={formData.worried_by || ''}
            onChange={handleChange('worried_by')}
            required={false}
          />
          <SubmitButton onSubmit={onSubmit} />
        </PromptHelperStyle.FillInTheBlanksSection>
      </ChatBubble>
    </Page.ECoachSection>
  );
}

ConversationPlanForm.propTypes = {
  handleSubmit: PropTypes.func,
};

FormTextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};
