import styled from 'styled-components';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import colours from 'constants/colours';
import * as InputStyle from 'components/pageECoach/_styles/MessageInputs.style';

export const AdviceList = styled(List)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
  width: 800px;
`;

export const FillInTheBlanksSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const OptionsToggle = styled(ListSubheader)`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0;
  position: unset;
`;

export const PromptButton = styled(Button)`
  background-color: ${colours.neutralCool[100]};
  border-radius: 10px;
  color: ${colours.neutralCool[800]};
  justify-content: unset;
  padding: 10px;
  text-transform: none;
  :hover {
    background-color: ${colours.neutralCool[200]};
  }
`;

export const PromptButtonDark = styled(PromptButton)`
  background-color: ${colours.neutralCool[500]};
  color: ${colours.shade[0]};
  :hover {
    background-color: ${colours.neutralCool[200]};
  }
`;

export const PromptOptions = styled(InputStyle.InputGroup)`
  gap: 20px;
`;
